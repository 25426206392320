<template>
  <div :class="['vg-input-block',{'vg-input-inline':inline}]">
    <label v-if="getTitle || !!$slots['title']" :class="['input-label',{'input-label-compacte':compacte}]">
        <slot name="title">{{ getTitle }}</slot>
        <span v-if="isRequiredValue" style="color:red">*</span>
        <div v-if="!!$slots['actions']" style="margin-left:10px;font-weight: normal;display: flex;justify-content: flex-start;align-items: center;gap:10px;">
          <slot name="actions"></slot>
        </div>
        <br v-if="subtitle">
        <span v-if="subtitle" style="font-size:10px;font-weight:normal !important;">{{subtitle}}</span>
    </label> <br v-if="getTitle || !!$slots['title']"/>
    <slot>
        <input :min="min"
          v-if="getInputType != 'textarea' && getInputType!='float' && getInputType!='financial' && isAutoGenerateHtmlInput "
          v-model="inputValue"
          :type="getInputType"
          :placeholder="placeholder?placeholder:getTitle"
          :class="['vg-input', { 'vg-input-not-empty-field': inputValue && inputValue.length, 'vg-input-important': important }]"
          :pattern="getPattern"
          :required="getIsRequiredValue"
          :disabled="isDisabled"
          :autocomplete="autocomplete"
          :ref="refInput"
          :id="id"
          :name="name"
          @keyup="onKeyup"
          @change="onChange"
          @blur="emitBlur"
          @focus="emitFocus" />
        <textarea v-else-if="getInputType == 'textarea'"
          v-model="inputValue"
          :class="[
            'vg-input-area',
            { 'vg-input-not-empty-field': inputValue && inputValue.length, 'vg-input-important': important }
          ]"
          :required="getIsRequiredValue"
          :disabled="isDisabled"
          :placeholder="placeholder"
          :rows="rows"
          :ref="refInput"
          :id="id"
          :name="name"
          @keyup="emitInput"
          @change="emitInput"
          @blur="emitBlur"
          @focus="emitFocus" />
        <input v-else-if="(getInputType=='float' || getInputType=='financial' || getInputType=='number') && isAutoGenerateHtmlInput"
            v-model="valueFloat"
            type="number"
            :step="inputStep"
            :class="['vg-input', { 'vg-input-not-empty-field': inputValue && inputValue.length, 'vg-input-financial': getInputType=='financial', 'vg-input-important': important }]"
            :required="getIsRequiredValue"
            :disabled="isDisabled"
            :placeholder="placeholder"
            :autocomplete="autocomplete"
            :ref="refInput"
            :id="id"
            :name="name"
            @blur="emitInput"
            @focus="emitFocus" />
        <div v-if="!isPasswordOk" class="password-not-ok">
          <span v-if="!isPasswordContient8Caracteres"><i class="fas fa-times"></i> {{ $t("password-8-caracteres") }}</span>
          <span v-if="!isPasswordContientLettreMajuscule"><i class="fas fa-times"></i> {{ $t("password-majuscule") }}</span>
          <span v-if="!isPasswordContientLettreMinuscule"><i class="fas fa-times"></i> {{ $t("password-minuscule") }}</span>
          <span v-if="!isPasswordContientChiffre"><i class="fas fa-times"></i> {{ $t("password-chiffre") }}</span>
          <span v-if="!isPasswordContientCaractereSpecial"><i class="fas fa-times"></i> {{ $t("password-caractere-special") }}</span>
        </div>
    </slot>
  </div>
</template>

<script>
export default {
  name: "vg-input",
  props: {
    /**
    * field label
    *
    */
    title: {
      type: String,
      //default: ""
    },
    /**
    * field id
    *
    */
    id: {
      type: String
    },
    /**
    * field name
    *
    */
    name: {
      type: String
    },
    /**
    * field subtitle
    *
    */
    subtitle: {
      type: String,
      default: null
    },
    /**
    * list attribute sur input
    */
    datalist: {
        type: String,
        default: null
    },
    /**
    * input type : standard html
    * `text, number, textarea, datetime-local, float, financial ...`
    */
    inputType: {
      type: String,
      default: "text"
    },
    /**
    * input step
    * default 0.01
    */
    inputStep: {
      type: String,
      default: "0.01"
    },
    /**
    * pattern : regexp
    * ex: [-+]?[0-9] , [0-9]
    */
    pattern: {
      type: String,
      default: null
    },
    /**
    * min : minimum value
    */
    min: {
      type: Number,
      default: null
    },
    /**
    * define if input required before submit
    *
    */
    isRequiredValue: {
      type: Boolean,
      default: false
    },
    /**
     * Uniquement utiliser pour le textarea
     * Nombre de ligne attribué pour le type textarea
     */
    rows:{
        type:Number,
        default:3
    },
    /**
    *  display or not default input field. exemple we want to display a custom input and we dont want
    *  this component to generate the basic input field. We will use the default slot to give our custom field.
    *  default true
    * @deprecated
    */
    autoGenerateHtmlInput: {
        type: Boolean,
        default: true
    },
    /**
    * disabled input if true. default false
    *
    */
    disabled:{
        type:Boolean,
        default:false
    },
    value : {
        type: [Object, String, Number],
        default : null
    },
    placeholder: {
        type: String,
        default: ""
    },
    /**
    * autocomplete cf https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
    * off | new-password | ...
    */
    autocomplete:{
        type: String,
        default: "off"
    },
    /**
    * reference de l'input
    */
    refInput:{
        type: String
    },
    important: {
        type: Boolean,
        default: false
    },
    inline:{
        type: Boolean,
        default: false
    },
    compacte:{
        type: Boolean,
        default: false
    },
  },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "password-8-caracteres": "Minimum de 8 caractères.",
            "password-majuscule": "Au moins une lettre majuscule.",
            "password-minuscule": "Au moins une lettre minuscule.",
            "password-chiffre": "Au moins un chiffre.",
            "password-caractere-special": "Au moins un caractère spécial."
        },
        "en": {
            "password-8-caracteres": "Minimum of 8 characters.",
            "password-majuscule": "At least one uppercase letter.",
            "password-minuscule": "At least one lowercase letter.",
            "password-chiffre": "At least one digit.",
            "password-caractere-special": "At least one special character."
        }
    }
},
  data:function() {
    return {
        inputValue: this.value,
        valueFloat: this.value,
        timeout: null
    };
  },
  watch: {
      value: {
          handler: function(newValue){
              this.setValue(newValue);
          }
      }
  },
  computed: {
    isPasswordContient8Caracteres: function(){
      return this.inputValue && this.inputValue.length>=8;
    },
    isPasswordContientLettreMajuscule: function(){
      if(!this.inputValue) return false;
      let regex = /[A-Z]+/;
      return regex.exec(this.inputValue)!==null?true:false;
    },
    isPasswordContientLettreMinuscule: function(){
      if(!this.inputValue) return false;
      let regex = /[a-z]+/;
      return regex.exec(this.inputValue)!==null?true:false;
    },
    isPasswordContientChiffre: function(){
      if(!this.inputValue) return false;
      let regex = /[0-9]+/;
      return regex.exec(this.inputValue)!==null?true:false;
    },
    isPasswordContientCaractereSpecial: function(){
      if(!this.inputValue) return false;
      let regex = /[#?!@$%^&*-]+/;
      return regex.exec(this.inputValue)!==null?true:false;
    },
    isPasswordOk: function(){
      // Don't show password requirements for confirm password fields
      if(this.inputType=="password" && this.name !== "password_confirm"){
        let regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
        return regex.exec(this.inputValue)!==null?true:false;
      }else return true;
    },
    isDisabled: function(){
        return this.disabled;
    },
    getTitle: function() {
      return this.title;
    },
    isAutoGenerateHtmlInput: function(){
        return this.autoGenerateHtmlInput;
    },
    getInputType: function() {
      return this.inputType;
    },
    getPattern: function() {
        var pattern = "*";
        var inputType = this.inputType;
        if(this.pattern) return this.pattern;
        if (inputType == "email") {
            pattern =
            "^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$";
        } else if (inputType == "number") {
            pattern = "[-+]?[0-9]";
        } else if (inputType == "password"){
            pattern = "(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}";
        }

      return pattern;
    },
    getIsRequiredValue: function() {
      return this.isRequiredValue;
    }
  },
    methods: {
        /**
        * @event delayed-input
        */
        emitDelayedInput: function(event){
            let value = null;
            let timer = 1;
            if( event && event.target.value ){
                value =  event.target.value;
                timer = 1000;
            }
            if (this.timeout !== null) {
                clearTimeout(this.timeout);
            }
            this.timeout = setTimeout(()=>{
                this.$emit("delayed-input", value);
            }, timer);
        },
        onKeyup: function(event){
            this.emitInput(event);
        },
        onChange: function(event){
            this.emitInput(event);
        },
        emitInput: function(event){
            if(this.getInputType=="float") this.$emit("input", this.valueFloat);
            else this.$emit("input", event.target.value);
            this.emitDelayedInput(event);
        },
        emitBlur:function(event){
            this.$emit("blur", event);
        },
        emitFocus: function(event){
            this.$emit("focus", event.target.value);
        },
        setValue: function(value){
            if(this.getInputType=="float" && typeof(value)=="string") this.valueFloat = value.replace(",",".");
            else if(this.getInputType=="float" && typeof(value)=="number") this.valueFloat = value;
            else if(this.getInputType=="financial") this.valueFloat = Number.parseFloat(value).toFixed(2);
            else this.inputValue = value;
        }
    },
    created: function(){
        this.setValue(this.value);
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.vg-input{
  font-size:16px !important;
}
.vg-input-area{
  font-size:16px !important;
}
.password-not-ok{
  border: 1px solid whitesmoke;
  background-color: whitesmoke;
  padding: 5px;
  font-size: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  i{
    color: red;
    margin-right: 2px;
  }
}
.vg-input-financial{
    text-align: right;
}
.vg-input-block {
  display: block;
  width: 100%;
  margin-bottom: 15px;
  font-family: "Open Sans";
}
.vg-input-not-empty-field {
  /*background-color: #f7fafc;*/
}
.vg-input {
  -webkit-appearance: none;
  background-image: none;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #40475b;
  display: inline-block;
  /*font-size: 17px;*/
  font-family: "Open Sans";
  height: 30px;
  /*line-height: 40px;*/
  outline: none;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  /*margin-top: 7px;*/
  &.mobile{
      /*font-size:15px;*/
  }
}
.vg-input-block ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #acabbb;
  font-size: 12px;
}

.vg-input-area {
  -webkit-appearance: none;
  background-image: none;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #40475b;
  display: inline-block;
  /*font-size: 18px;*/
  font-family: "Open Sans";
  line-height: 30px;
  outline: none;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  /*margin-top: 7px;*/
}
.vg-input:focus {
  border: 1px solid #2a9bd4;
}
.vg-input-area:focus {
  border: 1px solid #2a9bd4;
}
.vg-input-block {
  .input-label{
    color: #9e9da9;
    font-family: "Open Sans";
    font-size: 12px;
    margin-bottom: 10px;
    width: max-content;
    display: inline-block;
  }
  .input-label-compacte{
    margin-bottom: 0px;
    background-color: white;
    position: relative;
    top: 8px;
    z-index: 111;
    left: 3px;
    padding: 0 5px;
  }
}

.vg-input-important{
    border-color: red;
}

input{
	/* Firefox */
	-moz-appearance: textfield;

	/* Chrome */
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin:0;
	}

	/* Opéra*/
	&::-o-inner-spin-button {
		-o-appearance: none;
		margin: 0;
	}
}

/* inline */
.vg-input-inline{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .vg-input{
        width: inherit;
    }
    .vg-input-block{
        margin-bottom:0px !important;
        margin-right:15px;
    }
}
.vg-input-inline {
    .input-label{
      margin-bottom: 0px;
      white-space: nowrap;
      margin-right: 40px;
    }
}

</style>
<style lang="scss">
input, textarea, select {
  font-size: 16px !important;
}
</style>
<docs>

    ### vg-input examples:

    #### basic usage

    ```html static
          <vg-input title="Prénom"  inputType="text" :isRequiredValue="true" ></vg-input>
    ```
    #### Make a form with multiple input

    **Remarque**
    il faut mettre les input dans un formulaire et y ajouter un submit cacher, et déclencher ce submit via la méthode .click()
    les référence ajoutées sur les inputs permettent de récupérer l'élément du DOM. pour déclencher linput avec vuejs on peut alors faire
    ```javascript this.$refs['btnSubmit'].click()```

    ### Input Exemples in html form
    ```js
    new Vue({
      data(){
        return {
        }
      },
      template: `
        <div>
            <form ref="formulaire">
              <vg-input title="Nom"></vg-input>
              <vg-input title="Prénom" inputType="text" :isRequiredValue="true"></vg-input>
              <vg-input title="E.mail" inputType="email"></vg-input>
              <vg-input title="date" inputType="date"></vg-input>
              <vg-input title="textarea" inputType="textarea"></vg-input>
              <input v-show="false" type="submit" ref="btnSubmit" />
            </form>

        </div>
      `
    })
    ```

</docs>
