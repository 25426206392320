<template>
	<div class="vg-synthese-maintenance-preventive-table">
		<div class="header">
			<span class="main-cell">{{ $t("header-categorie") }}</span>
			<div class="month-cells">
				<span class="month-cell" v-for="(month, index) in months">{{ $t("month-"+month.month, {year: month.year}) }}</span>
			</div>
		</div>
		<div class="body">
			<div class="table-row-container" v-for="(categorie, indexCategorie) in syntheseGroupByCategories" 
				:key="'categorie-'+categorie.id">
				<div class="table-row section-row">
					<div class="main-cell">
						<div class="title">
							<div class="collapser" @click="onCollapseCategorie(categorie)">
								<vg-icon v-if="collapsedCategoriesIds.indexOf(categorie.id)!=-1" url="/static/assets/icone/custom/chevron-right.svg" size="md" :key="'categorie-icon-collapse-'+categorie.id" />
								<vg-icon v-else url="/static/assets/icone/custom/chevron-down.svg" size="md" :key="'categorie-icon-open-'+categorie.id"/>
							</div>
							<img v-if="categorie.icon" :src="categorie.icon" class="icon-categorie"/>
							<span>{{ categorie.libelleCatgorie }}</span>
						</div>
						<span class="subtitle">{{ $t("nb-equipements", {nb: categorie.nbEquipements}) }}</span>
					</div>
					<div class="month-cells">
						<div class="month-cell month-cell-clickable" v-for="month in months" :key="categorie.id+'-month-'+month.YYYYMM"
							@click="onClickDetailsSection(categorie, month)">
							<div v-if="!categorie.interventions || !categorie.interventions[month.YYYYMM]">
								-
							</div>
							<div v-else-if="categorie.interventions[month.YYYYMM] && (categorie.interventions[month.YYYYMM].nombre_interventions_realisees>0 || categorie.interventions[month.YYYYMM].nombre_interventions_non_validees>0)" 
								:class="'month-cell-badge badge-'+counterColor(categorie, month.YYYYMM)">
								<span :title="$t('tooltip-nb-interventions', {
									realisees: categorie.interventions[month.YYYYMM].nombre_interventions_realisees, 
									non_validees:categorie.interventions[month.YYYYMM].nombre_interventions_non_validees})">
									{{ calculNombreInterventions(categorie, month.YYYYMM) }}</span>
							</div>
							<div v-else>
								-
							</div>
						</div>
					</div>
				</div>
				<div v-for="(categorieContrat, index) in categorie.contrats"
					:class="['table-row section-container',{'section-container-hidden':collapsedCategoriesIds.indexOf(categorie.id)!=-1}]">
					<div class="main-cell">
						<div class="title-inside-container">
							<div class="contrat-icon">
								<vg-icon url="/static/assets/icone/custom/building.svg" size="xs" :key="'building-'+categorieContrat.contrat_id"/>
							</div>
							<span class="contrat-name" :title="$t('tooltip-contrat', {
								start: readableDate(categorieContrat.contrat_startDate), 
								end: readableDate(categorieContrat.contrat_endDate),
								type: categorieContrat.contrat_type, 
								tiers_name: categorieContrat.contrat_tiers_name})" 
								@click="openContrat(categorieContrat.contrat_id)">
								{{ categorieContrat.contrat_name }}
								<br>
								<span v-if="categorieContrat.affectation_id" style="font-size: 11px;color:darkcyan;">
									{{$t("programmation")}}: {{ readableRecurrence(categorieContrat) }} 
									<small>{{ readableTime(categorieContrat) }}
									({{ $t("generee-x-days-before", {days: categorieContrat.affectation_minDaysBeforeGeneratedDate}) }})</small>
								</span>
							</span>
						</div>
					</div>
					<div class="month-cells">
						<div class="month-cell month-cell-clickable" v-for="month in months" :key="categorieContrat.contrat_id+'-month-'+month.YYYYMM"
							@mouseover="showIconAdd.contrat_id=categorieContrat.contrat_id;showIconAdd.month=month.YYYYMM"
							@mouseleave="showIconAdd.contrat_id=null;showIconAdd.month=null"
							@click="onClickDetails(categorieContrat, month)">
							<div v-if="!categorieContrat.interventions">
								
							</div>
							<div v-else-if="categorieContrat.interventions[month.YYYYMM] && categorieContrat.interventions[month.YYYYMM].nombre_interventions_realisees!=null && categorieContrat.interventions[month.YYYYMM].nombre_interventions_non_validees!=null" 
								:class="'month-cell-badge badge-'+counterColor(categorieContrat, month.YYYYMM)">
								<span :title="$t('tooltip-nb-interventions', {
									realisees: categorieContrat.interventions[month.YYYYMM].nombre_interventions_realisees, 
									non_validees:categorieContrat.interventions[month.YYYYMM].nombre_interventions_non_validees})">
									{{ calculNombreInterventions(categorieContrat, month.YYYYMM) }}</span>
							</div>
							<div v-else-if="categorieContrat.interventions[month.YYYYMM] && categorieContrat.interventions[month.YYYYMM].nombre_interventions_programmees" 
								class="month-cell-badge badge-grey">
								<span :title="$t('tooltip-nb-interventions-programmees', {
									nb: categorieContrat.interventions[month.YYYYMM].nombre_interventions_programmees})">
									{{ categorieContrat.interventions[month.YYYYMM].nombre_interventions_programmees }}
								</span>
							</div>
							<div v-else>
								
							</div>
							<div v-if="!categorieContrat.interventions[month.YYYYMM] || !(categorieContrat.interventions[month.YYYYMM].nombre_interventions_realisees!=null && categorieContrat.interventions[month.YYYYMM].nombre_interventions_non_validees!=null)" 
								style="display: flex;justify-content: center;align-items: center;gap: 2px;">
								<vg-button 
									v-show="showIconAdd.contrat_id==categorieContrat.contrat_id && showIconAdd.month==month.YYYYMM" 
									size="sm" type="grey" :key="categorieContrat.contrat_id+'-month-'+month.YYYYMM+'-btn-add'"
									@click="onClickRowAdd(categorieContrat, month)">
									<vg-icon url="/static/assets/icone/custom/plus.svg" size="xs"/>
								</vg-button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<vg-modal v-if="showModalInterventions"
			:width="'60vw'"
			@close="onCloseModalInterventions">
			<template #header>
				<div v-if="selectedInterventions.categorieContrat">
					{{ $t("header-contrat", {month: $t("month-"+selectedInterventions.month), year: selectedInterventions.year}) }}
					<span style="font-weight: bold;margin-left:10px;">
						{{ selectedInterventions.categorieContrat.contrat_name }} - {{ selectedInterventions.categorieContrat.libelleCatgorie }}
					</span>
				</div>
				<div v-else-if="selectedInterventions.categorie">
					{{ $t("modal-header-categorie", {month: $t("month-"+selectedInterventions.month), year: selectedInterventions.year}) }}
					<span style="font-weight: bold;margin-left:10px;">
						{{ selectedInterventions.categorie.libelleCatgorie }}
					</span>
				</div>
			</template>
			<template #body>
				<div style="margin-bottom:5px;display: flex;justify-content: space-between;align-items: center;gap:10px;">
					<b>{{ $t("liste-des-interventions") }}</b>
					<small style="color:#333;">{{ $t("nb-interventions", {nb: selectedInterventions.counters.nombre_interventions_realisees+selectedInterventions.counters.nombre_interventions_non_validees}) }}</small>
				</div>
				<vg-interventions-table 
					view="cards"
					:agFilters="interventionsFilters"
					:paginate="false" />
			</template>
			<template #footer>
				<vg-button type="grey" v-if="selectedInterventions.categorieContrat"
					icon="add"
					@click="onClickAdd">
					{{ $t("add") }}
				</vg-button>
				<vg-button type="default-info" @click="onCloseModalInterventions">{{ $t("close") }}</vg-button>
			</template>
		</vg-modal>
		<vg-intervention-form v-if="showInterventionForm"
			:idContrat="selectedCategorieContrat.contrat_id"
			:idTiers="selectedCategorieContrat.contrat_tiers_id"
			:defaultDatePrevisionnelleDebut="selectedYYYYMM+'-01 08:00'"
			:defaultDatePrevisionnelleFin="selectedYYYYMM+'-01 09:00'"
           :isPeriodique="true"
           :defaultStatut="'intervention-prevue'"
			@close="showInterventionForm=false;selectedCategorieContrat=null;selectedYYYYMM=null;"
			@save="onSaveCreateIntervention"/>
	</div>
</template>
<script>

	import SyntheseMaintenanceMixins from "src/mixins/SyntheseMaintenanceMixins.js";

    import VgTextFilter from "src/components/Vg/VgTextFilter.vue";
    import VgTooltip from "src/components/Vg/VgTooltip.vue";
    import VgModal from "src/components/Vg/VgModal.vue";
    import VgButton from "src/components/Vg/VgButton.vue";
    import VgIcon from "src/components/Vg/VgIcon.vue";
	import VgInterventionsTable from "src/components/Vg/Interventions/VgInterventionsTable.vue";
	import VgInterventionForm from "src/components/Vg/Forms/VgInterventionForm.vue";

	import Metadatas from "src/services/Metadatas.js";
    import { mapGetters } from 'vuex';
	
    export default {
        name: 'vg-synthese-maintenance-preventive-table',
    	props: {
			startDate: {
				type: String,
				default: null
			},
			endDate:{
				type: String,
				default: null
			},
			/**
			* recherche
			*/
			searchQuery: {
				type: String,
				default: ""
			},
			/**
			* filtres externes appliqué sur le tableau au fetch
			*/
			filters: {
				type: Object,
				default: function(){
					return {};
				}
			},
        },
		watch: {
			filters: {
				handler: function(newfilters){
                    //this.metadatasContrats.setFilters(newfilters);
					this.fetch();
				},
				deep: true
			},
			searchQuery: {
				handler: function(newfilters){
					this.fetch();
				}
			},
			startDate: {
				handler: function(){
					this.fetch();
				}
			}
		},
		mixins: [
			SyntheseMaintenanceMixins
		],
		components: {
            VgTextFilter,
			VgTooltip,
			VgModal,
			VgButton,
			VgIcon,
			VgInterventionsTable,
			VgInterventionForm
		},
		i18n:    { "locale":navigator.language,
    "messages": {
		"fr": {
			"overlay-loading-text": "Chargement des données...",
			"overlay-no-rows-text": "Aucune donnée correspondant au filtrage",
			"header-categorie": "Catégorie",
			"month-01": "Janv",
			"month-02": "Fév",
			"month-03": "Mars",
			"month-04": "Avr",
			"month-05": "Mai",
			"month-06": "Juin",
			"month-07": "Juil",
			"month-08": "Août",
			"month-09": "Sept",
			"month-10": "Oct",
			"month-11": "Nov",
			"month-12": "Déc",
			"interne": "(interne)",
			"equipements": "eqts",
			"close": "Fermer",
			"prevu": "Prévues",
			"realisees": "Réalisées",
			"non-validees": "Non validées",
			"header-contrat": "Interventions - {month} {year}",
			"modal-header-categorie": "Interventions - {month} {year}",
			"liste-des-interventions": "Liste des interventions",
			"nb-interventions": "{nb} interventions programmées",
			"aucun-contrat": "Aucun contrat",
			"add-contrat": "Ajouter un contrat",
			"add-intervention": "Ajouter intervention",
			"nb-equipements": "{nb} équipements",
			"add": "Créer intervention prévisionnelle",
			"tooltip-nb-interventions": "Interventions réalisées: {realisees} - Interventions non validées: {non_validees}",
			"tooltip-nb-interventions-programmees": "{nb} intervention(s) à générer",
			"tooltip-contrat": "Date début: {start} - Date fin: {end} \nContrat type: {type} \nTiers: {tiers_name}",
			"aucune-programmation": "Aucune programmation",
            "tous-les-1": "Tous les jours {weekDay}",
            "tous-les-7": "Toutes les semaines {weekDay}",
            "tous-les-30": "Toutes les mois {weekDay}",
            "tous-les-90": "Toutes les trimestre {weekDay}",
            "tous-les-180": "Toutes les semestre {weekDay}",
            "tous-les-365": "Tous les ans {weekDay}",
            "tous-les-x-jours": "Tous les {jours} jours {weekDay}",
            "week-day-1": "le lundi",
            "week-day-2": "le mardi",
            "week-day-3": "le mercredi",
            "week-day-4": "le jeudi",
            "week-day-5": "le vendredi",
            "week-day-6": "le samedi",
            "week-day-7": "le dimanche",
            "generee-x-days-before": "Intervention générée {days} jours avant la date",
			"programmation": "Programmation des interventions"
		},
		"en": {
			"overlay-loading-text": "Loading datas...",
			"overlay-no-rows-text": "No datas matching the filters",
			"header-categorie": "Category",
			"month-01": "Jan",
			"month-02": "Feb",
			"month-03": "Mar",
			"month-04": "Apr",
			"month-05": "May",
			"month-06": "Jun",
			"month-07": "Jul",
			"month-08": "Aug",
			"month-09": "Sep",
			"month-10": "Oct",
			"month-11": "Nov",
			"month-12": "Dec",
			"interne": "(internal)",
			"equipements": "eqts",
			"close": "Close",
			"prevu": "Scheduled",
			"realisees": "Realized",
			"non-validees": "Not validated",
			"header-contrat": "Interventions - {month} {year}",
			"modal-header-categorie": "Interventions - {month} {year}",
			"liste-des-interventions": "List of interventions",
			"nb-interventions": "{nb} scheduled interventions",
			"aucun-contrat": "No contract",
			"add-contrat": "Add a contract",
			"add-intervention": "Add intervention",
			"nb-equipements": "{nb} equipments",
			"add": "Create scheduled intervention",
			"tooltip-nb-interventions": "Realized interventions: {realisees} - Non-validated interventions: {non_validees}",
			"tooltip-nb-interventions-programmees": "{nb} intervention(s) to generate",
			"tooltip-contrat": "Start date: {start} - End date: {end} \nContract type: {type} \nThird party: {tiers_name}",
			"aucune-programmation": "No programming",
			"tous-les-1": "Every day {weekDay}",
			"tous-les-7": "Every week {weekDay}",
			"tous-les-30": "Every month {weekDay}",
			"tous-les-90": "Every quarter {weekDay}",
			"tous-les-180": "Every semester {weekDay}",
			"tous-les-365": "Every year {weekDay}",
			"tous-les-x-jours": "Every {jours} days {weekDay}",
			"week-day-1": "Monday",
			"week-day-2": "Tuesday",
			"week-day-3": "Wednesday",
			"week-day-4": "Thursday",
			"week-day-5": "Friday",
			"week-day-6": "Saturday",
			"week-day-7": "Sunday",
			"generee-x-days-before": "{days} days before the date",
			"programmation": "Intervention programming"
		}
    }
},
        data: function(){
            return {
				showLoadingOverlay: false,
                metadatas: new Metadatas(),
				synthese: [],
				months: [],
				showModalInterventions: false,
				selectedInterventions: {
					categorie: null,
					categorieContrat: null,
					counters: null,
					month: null,
					year: null,
					YYYYMM: null
				},
				selectedCategorieContrat: null,
				selectedYYYYMM: null,
				showInterventionForm: false,
				showIconAdd: {contrat_id: null},
				collapsedCategoriesIds: []
            };
        },
		created: function(){
			this.initTableau();
			this.fetch().then(()=>{
				this.initCollapsedCategories();
			});
		},
		mounted: function(){
			window.addEventListener("askGenerateReport", this.generateReport);
		},
		methods: {
			onCollapseCategorie: function(categorie){
				let index = this.collapsedCategoriesIds.indexOf(categorie.id);
				if(index==-1) this.collapsedCategoriesIds.push(categorie.id);
				else this.collapsedCategoriesIds.splice(index, 1);
			},
			initCollapsedCategories: function(){
				this.synthese.forEach((categorie)=>{
					if(this.collapsedCategoriesIds.indexOf(categorie.id)==-1) this.collapsedCategoriesIds.push(categorie.id);
				});
			},
			onSaveCreateIntervention: function(){
				this.showInterventionForm = false;
				this.selectedCategorieContrat = null;
				this.selectedYYYYMM = null;
				this.fetch();
			},
			counterColor: function(categorieContrat, YYYYMM){
				let moisCourant = moment().format("YYYY-MM");
				let counter_realisees = categorieContrat.interventions[YYYYMM].nombre_interventions_realisees;
				let counter_non_validees = categorieContrat.interventions[YYYYMM].nombre_interventions_non_validees;
				let counter_programmees = categorieContrat.interventions[YYYYMM].nombre_interventions_programmees;
				if(counter_realisees>0 && counter_non_validees==0){
					return "green";
				}else if(counter_realisees==0 && counter_non_validees>0){
					if(YYYYMM<=moisCourant) return "red";
					else return "blue";
				}else if(counter_realisees>0 && counter_non_validees>0){
					if(YYYYMM<=moisCourant) return "orange";
					else return "blue";
				}else if(counter_programmees>0){
					return "grey";
				}else{
					return "null";
				}
			},
			readableDate: function(dt){
				if(!dt) return null;
				return moment(dt).format("DD/MM/YYYY");
			},
			readableRecurrence: function(categorieContrat){
				let readableRecurrence = "";
				let weekDay = "";
				if(categorieContrat.affectation_dayOfWeek) weekDay = this.$t("week-day-"+categorieContrat.affectation_dayOfWeek);
				if([1,7,30,90,180,365].includes(categorieContrat.affectation_recurrence)) readableRecurrence = this.$t("tous-les-"+categorieContrat.affectation_recurrence, {weekDay: weekDay}); 
				else readableRecurrence = this.$t("tous-les-x-jours", {jours: categorieContrat.affectation_recurrence, weekDay: weekDay});
				return readableRecurrence;
			},
			readableTime: function(categorieContrat){
				let start = moment(categorieContrat.affectation_start);
				let end = moment(categorieContrat.affectation_start).add(categorieContrat.affectation_durationMinutes, "minutes");
				return start.format("HH:mm")+" - "+end.format("HH:mm");
			},
			calculNombreInterventions: function(categorieContrat, YYYYmm){
				let nombreInterventions = 0;
				if(categorieContrat.interventions[YYYYmm].nombre_interventions_realisees!=null){
					nombreInterventions += categorieContrat.interventions[YYYYmm].nombre_interventions_realisees;
				}
				if(categorieContrat.interventions[YYYYmm].nombre_interventions_non_validees!=null){
					nombreInterventions += categorieContrat.interventions[YYYYmm].nombre_interventions_non_validees;
				}
				return nombreInterventions>0?nombreInterventions:null;
			},
			openContrat: function(contratId){
				this.$router.push({name: "_contrat_id", params: {id: contratId}});
			},
			onClickDetails: function(categorieContrat, month){
				if(!categorieContrat.interventions[month.YYYYMM]){
					return;
				}
				this.selectedInterventions.categorieContrat = categorieContrat;
				this.selectedInterventions.counters = categorieContrat.interventions[month.YYYYMM];
				this.selectedInterventions.month = month.month;
				this.selectedInterventions.year = month.year;
				this.selectedInterventions.YYYYMM = month.YYYYMM;
				this.showModalInterventions = true;
			},
			onClickDetailsSection: function(categorie, month){
				if(!categorie.interventions[month.YYYYMM]){
					return;
				}
				this.selectedInterventions.categorie = categorie;
				this.selectedInterventions.counters = categorie.interventions[month.YYYYMM];
				this.selectedInterventions.month = month.month;
				this.selectedInterventions.year = month.year;
				this.selectedInterventions.YYYYMM = month.YYYYMM;
				this.showModalInterventions = true;
			},
			onClickAdd: function(){
				this.selectedCategorieContrat = this.selectedInterventions.categorieContrat;
				this.selectedYYYYMM = this.selectedInterventions.YYYYMM;
				this.showInterventionForm = true;
			},
			onClickRowAdd: function(categorieContrat, month){
				this.selectedCategorieContrat = categorieContrat;
				this.selectedYYYYMM = month.YYYYMM;
				this.showInterventionForm = true;
			},
			onCloseModalInterventions: function(){
				this.showModalInterventions = false;
				this.selectedInterventions = {
					categorieContrat: null,
					counters: null,
					month: null,
					year: null,
					YYYYMM: null
				};
			},
			generateMonths: function(){
				let start = new Date(this.startDate);
				let end = new Date(this.endDate);
				let months = [];

				// Normalisation des dates pour commencer à la première date du mois
				start.setDate(1);
				end.setDate(1);

				let year = null;
				let month = null;

				// Boucle pour ajouter chaque mois à la liste
				while (start <= end) {
					year = start.getFullYear();
					month = start.getMonth() + 1; // Les mois commencent à 0, donc on ajoute 1
					months.push({year: year, month: month.toString().padStart(2, '0'), YYYYMM: year+"-"+month.toString().padStart(2, '0')});
					start.setMonth(start.getMonth() + 1); // Passer au mois suivant
				}

				this.months = months;
			},
			initTableau: function(){
				this.generateMonths();
			},
			fetch: function(){
				return new Promise((resolve, reject)=>{
					this.$emit("loading", true);
					let filters = Object.assign({}, this.filters, {
						libelleCatgorie: {attr: "cat.libelleCatgorie", value: this.searchQuery, action: "contains", openParenthesis: true},
						contrat_name: {attr: "c.name", value: this.searchQuery, action: "contains", logicalOperator: "OR"},
						contrat_type: {attr: "c.type", value: this.searchQuery, action: "contains", logicalOperator: "OR"},
						contrat_tiers_name: {attr: "t.name", value: this.searchQuery, action: "contains", logicalOperator: "OR", closeParenthesis: true}
					});
					this.metadatas.setFilters(filters);
					this.SyntheseMaintenanceMixins_get(this.startDate, this.endDate, this.metadatas).then((datas)=>{
						this.$emit("loading", false);
						this.synthese = datas.categoriesContrats;
						resolve(datas.categoriesContrats);
					});
				});
			},
		},
        computed:{
			...mapGetters({
				
            }),
			syntheseGroupByCategories: function(){
				const groupedCategories = {};
				// Parcourir les éléments de la synthèse
				this.synthese.forEach(item => {
					const categoryKey = item.libelleCatgorie;
					// Si la catégorie n'existe pas encore, l'initialiser
					if (!groupedCategories[categoryKey]) {
						groupedCategories[categoryKey] = {
							id: item.id,
							libelleCatgorie: item.libelleCatgorie,
							tags: item.tags,
							icon: item.icon,
							isGe: item.isGe,
							nbEquipements: item.nbEquipements,
							interventions: {},
							contrats: []
						};
					}
					// Ajouter le contrat à la catégorie correspondante
					groupedCategories[categoryKey].contrats.push(item);
					if(item.interventions){
						// Parcourir les mois d'interventions
						Object.keys(item.interventions).forEach((YYYYMM)=>{
							if(!groupedCategories[categoryKey].interventions[YYYYMM]){
								groupedCategories[categoryKey].interventions[YYYYMM] = {
									annee_mois: YYYYMM,
									nombre_interventions_programmees: 0,
									nombre_interventions_realisees: 0,
									nombre_interventions_non_validees: 0
								};
							}
							groupedCategories[categoryKey].interventions[YYYYMM].nombre_interventions_programmees += item.interventions[YYYYMM].nombre_interventions_programmees?item.interventions[YYYYMM].nombre_interventions_programmees:0;
							groupedCategories[categoryKey].interventions[YYYYMM].nombre_interventions_realisees += item.interventions[YYYYMM].nombre_interventions_realisees?item.interventions[YYYYMM].nombre_interventions_realisees:0;
							groupedCategories[categoryKey].interventions[YYYYMM].nombre_interventions_non_validees += item.interventions[YYYYMM].nombre_interventions_non_validees?item.interventions[YYYYMM].nombre_interventions_non_validees:0;
						});
					}
				});
				// Retourner les catégories regroupées sous forme de tableau
				return Object.values(groupedCategories);
			},
			interventionsFilters: function(){
				let startDate = new Date(this.selectedInterventions.counters.annee_mois);
				startDate.setDate(1);
				startDate = startDate.toISOString().split("T")[0];
				let endDate = new Date(this.selectedInterventions.counters.annee_mois);
				endDate.setMonth(endDate.getMonth() + 1);
				endDate = endDate.toISOString().split("T")[0];
				return {
					contrat_id: { attr:"i.contrat_id", value: this.selectedInterventions.categorieContrat?.contrat_id, action:"equals" },
					categorie_id: { attr:"cc.categorie_id", value: this.selectedInterventions.categorie?.id, action:"equals" },
					datePrevisionnelleDebut: { attr:"i.datePrevisionnelleDebut", value: [startDate, endDate], action:"between", openParenthesis: true},
					dateEffectiveDebut: { attr:"i.dateEffectiveDebut", value: [startDate, endDate], action:"between", logicalOperator: "OR", closeParenthesis: true}
				};
			},
        }
    };
</script>
<style lang="scss" scoped>
$backgroundColorCategorieRow: #ebebeb;
$backgroundColorContratRow: #f5f7f7;
$hover-accent-color: #3999FF;
.vg-synthese-maintenance-preventive-table{
	border: 1px solid #BDC3C7;
	font-size: 12px;
	height: 100%;
	.header{
		display: grid;
		grid-template-columns: minmax(250px, 1fr) 4fr;
		height: 40px;
		background-color: #f5f7f7;
		color: rgba(0, 0, 0, 0.54);
		font-weight: 600;
		font-size: 12px;
		font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
		border-bottom: 1px solid #BDC3C7;
	}
	.body{
		height: calc(100% - 40px);
		overflow-y: auto;
		.table-row{
			display: grid;
			grid-template-columns: minmax(250px, 1fr) 4fr;
			justify-self: stretch;
			align-items: stretch;
			border-bottom: 1px solid #d9dcde;
		}
		.table-row:hover{
			background-color: rgba(0, 0, 0, 0.07);
		}
		.section-row{
			background-color: $backgroundColorCategorieRow;//#E8EAEE;
		}
		.section-container{
			background-color: rgba(0, 0, 0, 0.04);
		}
	}
	.main-cell{
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		gap:5px;
		background-color: rgba(0, 0, 0, 0.02);
		border-right: 1px solid #d9dcde;
		padding: 5px 10px;
		.title{
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap:10px;
			font-weight: 600;
			font-size: 14px;
		}
		.subtitle{
			padding-left: 30px;
		}
		.title-inside-container{
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			gap:5px;
			padding-left: 10px;
			.contrat-icon{
				width: 20px;
				height: 20px;
			}
		}
		.contrat-name:hover{
			cursor: pointer;
		}
		.collapser{
			border: 1px solid transparent;
			border-radius: 50%;
		}
		.collapser:hover{
			cursor: pointer;
			border: 1px solid #BDC3C7;
		}
		.icon-categorie{
			width: 20px;
			height: 20px;
		}
	}
	.section-container-hidden{
		visibility: hidden;
    	height: 0px;
	}
	.month-cells{
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(20px, 1fr));
		background-color: rgba(0, 0, 0, 0.04);
	}
	.month-cell{
		border-right: 1px solid #d9dcde;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 2px;
		.month-cell-badge{
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			color: white;
			font-size: 10px;
			white-space: nowrap;
			padding: 6px 4px 4px 4px;
			border-radius: 4px;
		}
		.month-cell-badge:hover{
			
		}
		.badge-grey{
			background-color: #d2d5dd;
		}
		.badge-red{
			background-color: red;
		}
		.badge-orange{
			background-color: orange;
		}
		.badge-green{
			background-color: #50B659;
		}
		.badge-blue{
			background-color: #3999FF;
		}
	}
	.month-cell-clickable:hover{
		cursor: pointer;
		border: 1px solid $hover-accent-color;
	}
}
</style>
