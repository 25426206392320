<template>
    <vg-app-layout
        :title="inventaire.numeroInventaire"
        :colorheader="getColorHeader"
        :isZoomLayout="true"
        :isActionButtonDisplay="true"
        :activeFilters="activeFilters"
        :filtersTitle="$t('modal-filters-title')"
        @remove-filters="onRemoveFilters"
        @backpage="btnBackPage()">
        <template #header-top-left-complement>
                <i class="fas fa-trash"@click="isDeleteInventaireModaledisplayed=true"
                style="margin-left: 15px;color:#e90202;font-size: smaller;"
                ></i>
        </template>
        
        <template #action-button-item>
            <li @click="openRapportPrinter">{{$t("generer-rapport")}}</li>
        </template>
        <template #primary>

            <vg-collapse :title="$t('resume')"
                :collapseByDefault="false"
                :type="'section'">
                 <template v-slot:content>
                     <vg-libelle-editable :label="$t('name')">
                         <template v-slot:read>
                             {{inventaire.name}}
                         </template>
                     </vg-libelle-editable>

                     <vg-libelle-editable :label="$t('commentaire')">
                         <template v-slot:read>
                             {{inventaire.commentaire}}
                         </template>
                     </vg-libelle-editable>

                     <vg-libelle-editable :label="$t('numero-inventaire')">
                         <template v-slot:read>
                             {{inventaire.numeroInventaire}}
                         </template>
                     </vg-libelle-editable>

                     <vg-libelle-editable :label="$t('path')">
                         <template v-slot:read>
                             {{inventaire.path}}
                         </template>
                     </vg-libelle-editable>

                     <vg-libelle-editable :label="$t('statut')">
                         <template v-slot:read>
                             {{inventaire.statut}}
                         </template>
                     </vg-libelle-editable>

                     <vg-libelle-editable :label="$t('totalEquipement')">
                         <template v-slot:read>
                             {{inventaire.totalEquipement}}
                         </template>
                     </vg-libelle-editable>

                     <vg-libelle-editable :label="$t('totalInventorie')">
                         <template v-slot:read>
                             {{inventaire.totalInventorie}}
                         </template>
                     </vg-libelle-editable>

                 </template>
            </vg-collapse>

            <vg-collapse :title="$t('mouvements')"
                :collapseByDefault="false"
                :type="'section'">
                 <template v-slot:content>
                     <vg-libelle-editable :label="$t('not-found')">
                         <template v-slot:read>
                             {{getEtatInventaire.notFound}}
                         </template>
                     </vg-libelle-editable>
                     <vg-libelle-editable :label="$t('has-moved')">
                         <template v-slot:read>
                             {{getEtatInventaire.hasMoved}}
                         </template>
                     </vg-libelle-editable>

                 </template>
            </vg-collapse>

            <vg-collapse :title="$t('etat-equipements')"
                :collapseByDefault="false"
                :type="'section'">
                 <template v-slot:content>
                     <div class="etat-equipements-inline">
                         <vg-libelle-editable :label="$t('bon')">
                             <template v-slot:read>
                                 {{getEtatInventaire.bon}}
                             </template>
                         </vg-libelle-editable>
                         <vg-libelle-editable :label="$t('moyen')">
                             <template v-slot:read>
                                 {{getEtatInventaire.moyen}}
                             </template>
                         </vg-libelle-editable>
                         <vg-libelle-editable :label="$t('mauvais')">
                             <template v-slot:read>
                                 {{getEtatInventaire.mauvais}}
                             </template>
                         </vg-libelle-editable>
                     </div>
                 </template>
            </vg-collapse>

        </template>
        <template #secondary>
            <div class="stats-container">
                <div class="stat-item">
                    <div class="stat-value">{{inventaire.totalEquipement}}</div>
                    <div class="stat-label">{{$t('totalEquipement')}}</div>
                </div>
                <div class="stat-item">
                    <div class="stat-value">{{inventaire.totalInventorie}}</div>
                    <div class="stat-label">{{$t('totalInventorie')}}</div>
                </div>
                <div class="stat-item">
                    <div class="stat-value">{{getEtatInventaire.notFound}}</div>
                    <div class="stat-label">{{$t('not-found')}}</div>
                </div>
                <div class="stat-item">
                    <div class="stat-value">{{getEtatInventaire.hasMoved}}</div>
                    <div class="stat-label">{{$t('has-moved')}}</div>
                </div>
            </div>

            <div class="stats-container" style="margin-top: 20px;height:200px;">
                <vg-pie-chart
                    :datas="pieChartData"
                    class="stat-item"
                    :height="'100%'"
                    :title="$t('etat-equipements')"
                    :showTitle="true"
                    :showlegend="true">
                </vg-pie-chart>

                <vg-bar-chart
                    :datas="barChartData"
                    class="stat-item"
                    :height="'100%'"
                    :horizontalaxis="['Inventaire actuel']"
                    :title="$t('progression-inventaire')"
                    :showTitle="true"
                    :showlegend="true">
                </vg-bar-chart>
            </div>

            <div class="tabs-and-menu">
                <vg-tabs v-model="currentTabName" :tabs="tabs" :color="'bleu'"></vg-tabs>
                <action-menu-aggrid
                    :idTableau="currentTabName === 'lieu-id' ? 'tableau-inventaire-lieu-id-mobile' : 'tableau-inventaire-mobile'"
                    disabledReport>
                </action-menu-aggrid>
            </div>

            <div v-show="currentTabName === 'lieu-id'" style="height:400px;">
                <vg-inventaire-recapitulatif-lieu-id
                    :searchQuery="searchQuery">
                </vg-inventaire-recapitulatif-lieu-id>
            </div>
            <div v-show="currentTabName === 'recapitulatif'" style="height:400px;">
                <vg-inventaire-recapitulatif>
                </vg-inventaire-recapitulatif>
            </div>


        </template>
        <template v-if="$vgutils.isMobile()" #body>
            <!-- liste des pièces à inventorier de l'inventaire courant -->
            <vg-inventaire-recapitulatif
                :searchQuery="searchQuery"
                :agfilters="agfilters"
                @select-piece="onSelectPiece">
            </vg-inventaire-recapitulatif>
        </template>
        <template #search v-if="$vgutils.isMobile()">
            <vg-text-filter v-model="searchQuery" />
        </template>
        <template #panel-filters-localisation>
            <div></div> <!-- @WARNING doit être préserver pour afficher la modal de filtres contenant uniquement le widget lieux -->
        </template>
        <template #panel-widget-lieux>
            <vg-tree-lieux
                v-model="agfilters.path.value" />
        </template>

        <dialog-secured-action
          v-if="isDeleteInventaireModaledisplayed"
          :valid-response="'1'"
          @save="handleDeleteInventaire"
          @close="isDeleteInventaireModaledisplayed = false">
          <template v-slot:header-title>
            {{ $t("delete-inventaire") }}
          </template>
        </dialog-secured-action>
    </vg-app-layout>
</template>

<script>
import TagGrid from 'src/components/Grid/TagGrid.vue';
import VgInventaireCreateUpdateForm from "src/components/Vg/Inventaire/VgInventaireCreateUpdateForm.vue";
import VgTextFilter from 'src/components/Vg/VgTextFilter.vue';
import VgScanner from 'src/components/Vg/VgScanner.vue';
import TagGridMixins from 'src/mixins/TagGridMixins.js';
import LieuMixins from 'src/mixins/LieuMixins.js';
import InventaireMixins from "src/mixins/InventaireMixins.js";
import VgSwitch from 'src/components/Vg/VgSwitch.vue';
import VgSelect from 'src/components/Vg/VgSelect.vue';
import VgInput from 'src/components/Vg/VgInput.vue';
import VgChips from 'src/components/Vg/VgChips.vue';
import VgCollapse from 'src/components/Vg/VgCollapse.vue';
import VgButton from 'src/components/Vg/VgButton.vue';
import VgInventaireViewer from "src/components/Vg/Inventaire/VgInventaireViewer.vue";
import VgInventaireRecapitulatif from "src/components/Vg/Inventaire/VgInventaireRecapitulatif.vue";
import VgInventaireRecapitulatifLieuId from "src/components/Vg/Inventaire/VgInventaireRecapitulatifLieuId.vue";
import VgLibelleEditable from 'src/components/Vg/Forms/VgLibelleEditable.vue';
import ActionMenuAggrid from "src/components/Vg/TagGrid/ActionMenuAggrid.vue";
import DialogSecuredAction from 'src/components/Vg/DialogSecuredAction.vue';
import VgTreeLieux from "src/components/Vg/Lieu/VgTreeLieux.vue";
import VgFilterSite from "src/components/Vg/Filters/VgFilterSite.vue";
import VgTabs from 'src/components/Vg/VgTabs.vue';
import VgPieChart from "src/views/Statistiques/components/VgPieChart.vue";
import VgBarChart from "src/views/Statistiques/components/VgBarChart.vue";

import { mapGetters } from 'vuex';
export default {
    name: "vg-inventaire",
    components: {
        TagGrid,
        VgTextFilter,
        VgSwitch,
        VgSelect,
        VgInput,
        VgChips,
        VgButton,
        VgInventaireCreateUpdateForm,
        VgCollapse,
        VgInventaireViewer,
        VgInventaireRecapitulatif,
        VgInventaireRecapitulatifLieuId,
        VgLibelleEditable,
        ActionMenuAggrid,
        DialogSecuredAction,
        VgTreeLieux,
        VgFilterSite,
        VgTextFilter,
        VgTabs,
        VgPieChart,
        VgBarChart
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "title": "Inventaire",
            "resume": "Résumé",
            "etat-equipements": "Etat équipements",
            "mouvements": "Mouvements",
            "name": "Libellé",
            "commentaire": "Commentaire",
            "numero-inventaire": "Numéro inventaire",
            "path": "Localisation",
            "statut": "Statut",
            "totalEquipement": "Total équipements",
            "totalInventorie": "Total inventoriés",
            "not-found": "Equipements Non trouvés",
            "has-moved": "Mouvements",
            "modal-filters-title": "Filtrer l'inventaire",
            "lieu-id": "Liste équipements à recenser",
            "recapitulatif": "Résumé par pièce",
            "progression-inventaire": "Progression de l'Inventaire",
            "inventoried": "Inventoriés",
            "non-inventoried": "Non inventoriés"
        },
        "en": {
            "title": "Assets tracking",
            "resume": "Recap",
            "etat-equipements": "Equipment states",
            "mouvements": "Moves",
            "name": "Name",
            "commentaire": "Comment",
            "numero-inventaire": "Assets tracking number",
            "path": "Location",
            "statut": "Status",
            "totalEquipement": "Equipment total",
            "totalInventorie": "inventoried total",
            "not-found": "Not found equipments",
            "has-moved": "Moves",
            "modal-filters-title": "Filter assets tracking",
            "lieu-id": "List of assets to be inventoried",
            "recapitulatif": "Summary by piece",
            "progression-inventaire": "Inventory Progress",
            "inventoried": "Inventoried",
            "non-inventoried": "Non-inventoried"
        },
        "th": {
            "title": "สินค้าคงคลัง"
        }
    }
},
    mixins: [
        TagGridMixins,
        LieuMixins,
        InventaireMixins
    ],
    data: function() {
        return {
            isCreateInventaireModalOpened:false,
            isScannerOpened:false,
            isDeleteInventaireModaledisplayed:false,
            agfilters: {
                path: {attr: "path", value:null, action: "equals"}
            },
            searchQuery: '',
            currentTabName: 'lieu-id',
            tabs: [
                { label: this.$t("lieu-id"), name: "lieu-id" },
                { label: this.$t("recapitulatif"), name: "recapitulatif" }
            ]
        }
    },
    watch:{
        /*agfilters: {
            handler: function(newfilters){
                // @WARNING fetch n'utilise pas de metadatas => filtrage en local
                this.TagGridMixins_setQuickFilter(this.gridOptions, newfilters.path.value);
            },
            deep: true
        }*/
    },
    computed: {
        ...mapGetters({
            inventaire: "InventairesStore/getSelectedItem",
            stateByPath: 'InventairesStore/getStateByPath',
            operationsInventaire: 'OperationsInventairesStore/getCollection'
        }),
        pageFilters: function(){
            return ["path"];
        },
        activeFilters: function(){
            let filtersNames = this.pageFilters;
            let activeFilters = filtersNames.filter((filterName)=>this.agfilters[filterName].value!=null && (this.agfilters[filterName].value && this.agfilters[filterName].value.length!=0));
            let nFilters = 0;
            if(this.searchQuery && this.searchQuery.length!=0) nFilters += 1;
            return activeFilters.length + nFilters;
        },
        getHeaderBackgroundColor: function(){
            return this.$vgutils.isMobile() ? HEADER_BACKGROUND_COLOR_MOBILE : "";
        },
        getColor:function(){
            return this.$vgutils.isMobile() ? "#ffffff" : "black";
        },
        getColorHeader:function(){
            return this.$vgutils.isMobile() ? "#429fff" : "white";
        },
        getEtatInventaire:function(){
            let bon = 0;
            let moyen = 0;
            let mauvais = 0;
            let notFound = 0;
            let hasMoved = 0;
            this.operationsInventaire.map((operationInventaire)=>{
                if(operationInventaire.etat == "Bon"){
                    ++bon;
                }
                else if (operationInventaire.etat == "Moyen") {
                    ++moyen
                }
                else if (operationInventaire.etat == "Mauvais") {
                    ++mauvais
                }
                if(operationInventaire.statut == "notFound"){
                    ++notFound;
                }
                if(operationInventaire.hasMoved){
                    ++hasMoved;
                }
            });
            return {"bon":bon,"moyen":moyen,"mauvais":mauvais,"notFound":notFound,"hasMoved":hasMoved};
        },
        pieChartData() {
            return [
                {
                    label: [this.$t('bon'), this.$t('moyen'), this.$t('mauvais')],
                    data: [
                        this.getEtatInventaire.bon,
                        this.getEtatInventaire.moyen,
                        this.getEtatInventaire.mauvais
                    ],
                    backgroundColor: ['#4CAF50', '#FFC107', '#F44336']
                }
            ];
        },
        barChartData() {
            return [
                {
                    label: this.$t('inventoried'),
                    backgroundColor: '#3b82f6',
                    data: [this.inventaire.totalInventorie]
                },
                {
                    label: this.$t('non-inventoried'),
                    backgroundColor: '#d1d5db',
                    data: [this.inventaire.totalEquipement - this.inventaire.totalInventorie]
                }
            ];
        }
    },
    methods: {
        onRemoveFilters: function(){
            this.pageFilters.forEach((filterName)=>{
                this.agfilters[filterName].value = null;
            });
            this.searchQuery = null;
        },
        onSelectPiece: function(row){
            this.$router.push({ name: '_vg_inventaire_id_lieu_id', params: { id: this.$route.params.id, lieuId:row.data.lieu_id }});
        },
        btnBackPage:function(){
            this.$router.back();
        },
        fetch: function(){
            // récupère la liste des pièces à inventorier dans l'inventaire en cours
            this.InventaireMixins_fetchById(this.$route.params.id).then((inventaire)=>{
                // récupère pour l'inventaire les équipements inventoriés et non inventoriés
                this.InventaireMixins_fetchOperationsByInventaireId(this.$route.params.id);
            });
        },
        handleDeleteInventaire:function(){
            console.log("delete inventaire ",this.$route.params.id)
            this.InventaireMixins_deleteInventaire(this.$route.params.id).then(()=>{
                this.isDeleteInventaireModaledisplayed = false;
                this.$router.go(-1);
            })

        }
    },
    created: function() {
        this.fetch();
    }
}
</script>

<style lang="scss">
.stats-container {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    gap: 20px;
}

.stat-item {
    text-align: center;
    flex: 1;
    background: #ffffff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 16px;
    transition: transform 0.2s ease-in-out;
}

.stat-item:hover {
    transform: translateY(-5px);
}

.stat-value {
    font-size: 24px;
    color: #3b82f6;
    font-weight: bold;
}

.stat-label {
    font-size: 14px;
    color: #6b7280;
    margin-top: 8px;
}

.tabs-and-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.etat-equipements-inline {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    justify-content: center;
}
</style>
