
<template lang="html">
	<div class="vg-files-viewer-list">
		<div v-for="file, index in value" :key="index">
			<vg-files-viewer-document :file="file" 
				:showTag="showTag"
				@click-link="onFileClick" 
				:key="'fv-'+index"/>
			<!--vg-button
				:size="'sm'"
				:type="'default-grey'"
				>voir
			</vg-button-->
		</div>
	</div>
</template>
<script>
	import VgFilesViewerDocument from "src/components/Vg/Files/VgFilesViewerDocument.vue";
	import VgButton from "src/components/Vg/VgButton.vue";
	import VgFileViewer from 'src/components/Vg/Files/VgFileViewer.vue';
	export default {
        name: 'vg-files-viewer-list',
	    components: {
			VgFilesViewerDocument,
			VgButton,
			VgFileViewer
        },
        props: {
			/**
			* @model
			*/
			value: {
				type: Array,
				required: true
			},
			showTag:{
				type: Boolean,
				default: false
			}
        },
        data: function() {
            return {
				selectedFile: null
			};
        },
        methods: {

			onFileClick: function(filelink){
				fetch(filelink).then((response)=>{
					response.blob().then((blob) => {
						this.selectedFile = new File([blob], "filename", { type: blob.type });
					});
	
				})
			}
		},
	};
</script>

<style lang="scss" scoped>
.vg-files-viewer-list{
	width: 100%;
	>div{
		margin-bottom: 7px;
		display: flex;
		justify-content: space-between;
	}
	>div:hover{
		color:#57c1db;
	}
}
</style>
