<template>
    <vg-input v-if="onlyYear"
        :title="$t('year')"
        compacte>
        <vg-select
            v-model="dateYear"
            :options="yearRange"
            :clearable="false"
            style="width:100%;"
            @input="handleChangeYear">
        </vg-select>
    </vg-input>
    <div v-else class="vg-date-picker">
        <input
            class="date-picker-start"
            v-model="dateMin"
            :type="type"
            @change="handleChange"
        />
        <span>{{$t("au")}}</span>
        <input
            class="date-picker-end"
            v-model="dateMax"
            :min="dateMin"
            :type="type"
            @change="handleChange"
        />
    </div>
</template>

<script>
import moment from "moment";
import VgSelect from "src/components/Vg/VgSelect.vue";
import VgInput from "src/components/Vg/VgInput.vue";
export default {
    name: "vg-date-picker",
    components:{
        VgSelect,
        VgInput
    },
    props: {
        from : {
            type: String,
            default: function(){
                return moment().format("YYYY-MM-DD");
            }
        },
        to : {
            type: String,
            default: function(){
                return moment().format("YYYY-MM-DD");
            }
        },
        type: {
            type: String,   // date || datetime-local
            default: "date"
        },
        onlyYear:{
            type: Boolean,
            default: false
        },
        outputFormat:{
            type: String,
            default: "YYYY-MM-DD HH:mm:ss"
        },
    },
    data: function() {
        return{
            dateMin: this.getInputDate(this.from),
            dateMax: this.getInputDate(this.to),
            dateYear: moment().format("YYYY"),
            currentYear: parseInt(moment().format("YYYY"), 10)
        };
    },
    i18n:    { "locale":navigator.language,
    "messages": {
        "fr": {
            "au": "au",
            "year": "Année",
        },
        "en": {
            "au": "to",
            "year": "Year",
        },
        "th": {
            "au": "ถึง"
        }
    }
},
    watch:{
        
    },
    methods:{
        getInputDate: function(date){
            if(this.type=="date") return date;
            else return moment(date).format("YYYY-MM-DDTHH:mm");
        },
        getDefaultDateFormat: function(date){
            if(this.type=="date") return date;
            else return moment(date).format("YYYY-MM-DD HH:mm:ss");
        },
        handleChange: function(e){
            let dateMin = moment(this.dateMin);
            let dateMax = moment(this.dateMax);
            if( dateMin > dateMax ){
                dateMax = dateMin;
                this.dateMax = dateMax.add(30,"m").format("YYYY-MM-DDTHH:mm:ss");
                this.$forceUpdate();
            }
            this.$emit("change",
                {
                    dateMin: this.getDefaultDateFormat(this.dateMin),
                    dateMax: this.getDefaultDateFormat(this.dateMax)
                }
            );
        },
        handleChangeYear: function(selectedYear){
            this.dateMin = `${selectedYear}-01-01`;
            this.dateMax = `${selectedYear}-12-31`;
            this.$emit("change", {
                dateMin: this.dateMin,
                dateMax: this.dateMax
            });
        }
    },
    computed:{
        yearRange: function() {
            const startYear = this.currentYear - 2; // 5 years before current year
            const endYear = this.currentYear + 5; // 5 years after current year
            return Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i);
        }
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .vg-date-picker{
        display: flex;
        justify-content: space-between;
        align-items: center;
        span{
            color: #9e9da9;
            font-size: 0.85em;
        }
    }
    .date-picker-start , .date-picker-end{
        text-align: center;
        color: #252525;
        font-family: "Open Sans";
        font-size: 16px !important;
        width: 45%;
    }
    select.date-picker-start {
        appearance: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 5px;
        font-size: 16px;
        width: 100%;
    }
    @media only screen and (max-width: 768px) {
        .vg-date-picker{
            flex-direction: column;
            select,
            input{
                width: 100%;
            }
            span{
                display: block;
                height: 20px;
                width: 100%;
                text-align: center;
                margin: 3px 0;
            }
        }
    }
</style>
