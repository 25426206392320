
<template lang="html">
	<div :class="['viewer-document-container', {'underline': noDownload}]" @click="handleClickDocument">
		<i class="fa-lg far fa-file"></i>
		<span>{{fileToShow.name}}</span>
		<span v-if="showTag">&nbsp;({{fileToShow.label}})</span>
		<vg-file-viewer v-if="loadedFileInViewer" :file="loadedFileInViewer" @close="loadedFileInViewer = null"/>
	</div>
</template>
<script>
	import VgFileViewer from 'src/components/Vg/Files/VgFileViewer.vue';
	export default {
        name: 'vg-files-viewer-document',
	    components: {
			VgFileViewer
        },
		filters: {

		},
		mixins:[

        ],
        props: {
			file: {	// @TODO utiliser v-model
				type: Object,
				required: true
			},
			noDownload:{
				type: Boolean,
				default: false
			},
			isRenderedInThisComponent:{
				type: Boolean,
				default: true
			},
			showTag:{
				type: Boolean,
				default: false
			}
        },
        data: function() {
            return {
 				fileToShow: this.file,
				loadedFileInViewer :null
			};
        },
		watch: {
			file: {
				handler: function(f){
					this.fileToShow = f;
					this.loadedFileInViewer = f;
				},
				deep: true
			}
		},
		filters:{

		},
		created: function(){

		},
        mounted: function(){

        },
        methods: {
			displayFile(filelink){
				fetch(filelink).then((response)=>{
					response.blob().then((blob) => {
						this.loadedFileInViewer = new File([blob], "bon-entre_sortie", { type: blob.type });
					});

				})
			},
			handleClickDocument: function(){
				this.VgFilesMixins_getDownloadFileLink(this.fileToShow).then((link)=>{
					if(!this.noDownload) this.VgFilesMixins_downloadFile(this.fileToShow, link);
					else{
						if(this.isRenderedInThisComponent){
							this.displayFile(link);
						}else{
							this.$emit("click-link",link);
						}
					}
				});
			}
		},
		computed: {

		}
	};
</script>

<style lang="scss" scoped>
.viewer-document-container{
	width: 100%;
	cursor: pointer;
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;
	i{
		margin-right: 5px;
	}
}
.underline{
	color: #3999ff;
	text-decoration: underline;
}
</style>
