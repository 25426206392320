import { render, staticRenderFns } from "./VgFilesViewerList.vue?vue&type=template&id=5c4a6538&scoped=true&lang=html"
import script from "./VgFilesViewerList.vue?vue&type=script&lang=js"
export * from "./VgFilesViewerList.vue?vue&type=script&lang=js"
import style0 from "./VgFilesViewerList.vue?vue&type=style&index=0&id=5c4a6538&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c4a6538",
  null
  
)

export default component.exports